import '../App.css';
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer';
import { globalVar } from '../globalVar';
function Ogloszenia() {
  return (<>
        <Navigation />
        <div className='article'>
            <div className='title'>Ogłoszenia {globalVar.map((map,index) => {return map.niedziela})} </div>
            <p>Kalendarz Liturgiczny</p>
            <ul>
                <li>poniedziałek- wspomnienie NMP Różańcowej</li>
                <li>środa - wspomnienie bł.Wincentego Kadłubka, biskupa</li>
            </ul>
            <p>
                Nabożeństwa różańcowe:
            </p>
            <ul>
                <li>w dni powszednie po Mszy św. o godz. 18.00,</li>
                <li>w niedzielę po Mszy św. o godz. 11.00,</li>
                <li>w kaplicy na Łowiczkach w środy o godz. 16.30.</li>
            </ul>
            <p>Zachęcam do gorliwego i licznego udziału w tym nabożeństwie wszystkich parafian, zwłaszcza dzieci i młodzież.</p>
            <p>
                W poniedziałek święto patronalne Róż Różańcowych, zachęcam wszystkich
                członków naszych Róż do uczestnictwa we Mszy św. o godz. 18.00 i nabożeństwie
                różańcowym.
            </p>
            <p>
                W sobotę o godz. 10.00 Msza św. dziękczynna, kończąca sezon motocyklowy,
                zapraszam na to spotkanie modlitewne, jak i po niej zapewne prócz oglądnięcia motocykli
                będzie można skorzystać z przejażdżki.
            </p>
            <p>
                W przyszłą niedzielę zakończenie tegorocznych nabożeństw fatimskich w naszej
                wspólnocie. Msza św. o godz. 16.00 i nabożeństwo różańcowe (nie będzie nabożeństwa po
                Mszy św. o godz. 11.00). Na tej Mszy św. kandydaci do bierzmowania z klas VIII–ych
                rozpoczną 9-cio tygodniową nowennę przed przyjęciem sakramentu. Zacpraszam również
                rodziców, chrzestnych, bliskich.
            </p>
            <p>
                Przyszła niedziela, w naszym kraju jest obchodzona jako XXIV Dzień Papieski pod
                hasłem: „Jan Paweł II. Ewangelia starości i cierpienia”. Tradycyjnie ofiarą do puszek
                będzie można wspomóc ufundowanie przez Fundację „Dzieło nowego tysiąclecia”
                stypendiów dla dzieci i młodzieży.
            </p>
            <p>
                W poniedziałek 14-go października obchodzimy Dzień Edukacji. Na Mszę św. o
                godz. 18.00 i nabożeństwo różańcowe zapraszam dyrekcję, nauczycieli i wychowawców
                naszej szkoły i przedszkola. Naszą modlitwą obejmiemy ich w tym dniu, prosząc o
                potrzebne dla nich łaski, światło Ducha Świętego i Boże błogosławieństwo.
            </p>
            <p>
                Kancelaria parafialna w dniach 7 – 11 październik (od poniedziałku do piątku),
                będzie nieczynna w związku z wyjazdem na rekolekcje kapłańskie. Zastępował mnie
                będzie ks.Zbigniew, w razie nagłej potrzeby (posługi chorym) będzie służył.
            </p>
            <p>
                Dziękuję za złożone w miesiącu wrześniu (do kopert i na konto parafialne) ofiary na
                nasze prace inwestycyjne. Złożono kwotę 19650zł. Niech Bóg wynagradza i błogosławi
                wszystkim ofiarodawcom.
            </p>
            <p>Dziękuję za sprzątanie świątynii na dzisiejszą niedzielę, i ofiarę 100zł, na przyszłą niedzielę troskę o świątynię powierzamy rodzinom:</p>
            <ul>
                <li>Małgorzaty Kozłowskiej</li>
                <li>Łukasza i Katarzyny Ortman</li>
                <li>Wiesława i Małgorzaty Ortman</li>
                <li>Mariusza Koczura i Marty Ortman</li>
                <li>Pawła i Sylwii Grubka</li>
            </ul>
            <p>
                Dziękuję za sprzątanie kaplicy i ofiarę 100zł, w tym tygodniu troskę o kaplicę powierzamy:
            </p>
            <ul>
                <li>Dorocie Skowron</li>
                <li>Liliannie Gurdek</li>
                <li>Natalii Gurdek</li>
                <li>Agnieszce Jezierskiej</li>
            </ul>
            <p>
                Z naszej Wspólnoty do wieczności Pan Bóg powołał:
                śp.Bogumiłę Cieślik z ul.Modrzewiowej.
                Dobry, Jezu, a nasz Panie, daj jej wieczne spoczywanie.
            </p>
        </div>
        <Footer />
  </>
  );
}

export default Ogloszenia;
