import '../App.css'
import Navigation from '../componets/Navigation'
import Footer from '../componets/Footer'
import './repeat.css'

function Ofiarodawcy(){
    return (
        <>
            <Navigation />
            <div className='article'>
            <div className='title'>OFIARA NA POTRZEBY KOŚCIOŁA WRZESIEŃ 2024</div>
            <ol>
                <li>A. - Łowiczki Centralna 85 100zł</li>
                <li>Brzeźniak &ndash; Łowiczki Graniczna 38 100zł</li>
                <li>R.Chojna &ndash; Łowiczki 100zł</li>
                <li>Czopek &ndash; Łowiczki 216 400zł</li>
                <li>Tadeusz Gancarczyk &ndash; Łowiczki Centralna 28 100zł</li>
                <li>Jan Gliwa &ndash; Łowiczki Centralna 8 300zł</li>
                <li>A.Jezierscy &ndash; Łowiczki Centralna 260 100zł</li>
                <li>B.Jezierscy &ndash; Łowiczki Centralna 194 100zł</li>
                <li>Anna Kciuk &ndash; Łowiczki Centralna 6 &nbsp; 50zł</li>
                <li>Kozaczka &ndash; Łowiczki Kanada 22 50zł</li>
                <li>Urszula Jan Kreczmer &ndash; Łowiczki 100zł</li>
                <li>R.Leszczyńscy &ndash; Łowiczki 200zł</li>
                <li>A.Majkut &ndash; Łowiczki Centralna 85 100zł</li>
                <li>Krystyna Matla &ndash; Łowiczki 100zł</li>
                <li>Miętka &ndash; Łowiczki 100zł</li>
                <li>K.Stachura &ndash; Łowiczki 100zł</li>
                <li>A.Wojtaszek &ndash; Łowiczki 100zł</li>
                <li>J&oacute;zefa Wolanin &ndash; Łowiczki Graniczna 22 100zł</li>
                <li>Cecylia Piotr Woźniak &ndash; Łowiczki Centralna 85 100zł</li>
                <li>Jerzy W&oacute;jcik &ndash; Łowiczki Cyprysowa 15 100zł</li>
                <li>Bogusława Zając &ndash; Łowiczki 100zł</li>
                <li>Franciszka Zięba &ndash; Łowiczki 100zł</li>
                <li>Bronisława Antecka 100zł</li>
                <li>L.Anteccy &ndash; Andrychowska 13 300zł</li>
                <li>Kazimiera Augustyn &ndash; Modrzewiowa 42 50zł</li>
                <li>Bałys &ndash; Andrychowska 346 100zł</li>
                <li>Bernaś, Momot &ndash; Akacjowa 31 100zł</li>
                <li>Mieczysław Bernaś &ndash; Modrzewiowa 47 100zł</li>
                <li>Maria Stanisław Boba &ndash; Andrychowska 352 100zł</li>
                <li>Burzyca, Solarz &ndash; Pag&oacute;rkowa 28 200zł</li>
                <li>G.Całus 100zł</li>
                <li>J.Dedko &ndash; Andrychowska 3 100zł</li>
                <li>J.Domider &ndash; Andrychowska 216 100zł</li>
                <li>Barbara Piotr Franica &ndash; Kasztanowa 124 100zł</li>
                <li>Frączek &ndash; Andrychowska 157 200zł</li>
                <li>Frączek &ndash; Andrychowska 265 100zł</li>
                <li>Anna Frączek &ndash; Wrzosowa 22 200zł</li>
                <li>R.Frączek &ndash; Akacjowa 6 100zł</li>
                <li>A.Frączek &ndash; Kasztanowa 44 100zł</li>
                <li>J.Frączek &ndash; Andrychowska 159 100zł</li>
                <li>R.Frączek &ndash; Andrychowska 227 100zł</li>
                <li>J.Frączek 50zł</li>
                <li>Władysław Frączek &ndash; Andrychowska 287 100zł</li>
                <li>Jacek Marta Gagracz &ndash; Kasztanowa 132 200zł</li>
                <li>Jerzy Gagracz &ndash; Węglarz&oacute;wka 11 100zł</li>
                <li>Marcin Gagracz &ndash; Akacjowa 96 100zł</li>
                <li>M.Gorzkowski 100zł</li>
                <li>N.Głąb &ndash; Modrzewiowa 45 100zł</li>
                <li>J&oacute;zef Głąb &ndash; Modrzewiowa 200zł</li>
                <li>G&oacute;reccy, Kobiałka 200zł</li>
                <li>T.G&oacute;reccy &ndash; Lipowa 14 100zł</li>
                <li>Alojzy G&oacute;recki 200zł</li>
                <li>Edyta Franciszek G&oacute;reccy &ndash; Akacjowa 44 100zł</li>
                <li>Czesława Gluzicka &ndash; Akacjowa 86 50zł</li>
                <li>Elżbieta Gregorczyk &ndash; Wrzosowa 26 100zł</li>
                <li>Hałat, Gryska &ndash; Andrychowska 305 100zł</li>
                <li>L.Janik &ndash; Pag&oacute;rkowa 19 100zł</li>
                <li>Jarosz &ndash; Andrychowska 244 50zł</li>
                <li>Jurecki &ndash; Kasztanowa 73 100zł</li>
                <li>Piotr Marcin Jureccy &ndash; Andrychowska 2 100zł</li>
                <li>A.Kalman 100zł</li>
                <li>Renata Kobylańska 100zł</li>
                <li>Kocjan 100zł</li>
                <li>A. Kolasa &ndash; Andrychowska 173 200zł</li>
                <li>Stanisława Kolasa &ndash; Andrychowska 171 100zł</li>
                <li>Knutel &ndash; Modrzewiowa 3 50zł</li>
                <li>Stanisław Kowalczyk &ndash; Modrzewiowa 14 200zł</li>
                <li>Kozak &ndash; Modrzewiowa 24 100zł</li>
                <li>P.Kozak &ndash; Andrychowska 233 50zł</li>
                <li>P.Kozak &ndash; Andrychowska 233 50zł</li>
                <li>Maria Kozak &ndash; Andrychowska 208 100zł</li>
                <li>Kozieł &ndash; Andrychowska 165 100zł</li>
                <li>Zofia Kozieł &ndash; Modrzewiowa 20 100zł</li>
                <li>K.Krawczyk &ndash; Akacjowa 100zł</li>
                <li>B.Krawczyk &ndash; Akacjowa 34 50zł</li>
                <li>K.Krzeczyńscy &ndash; Kasztanowa 95 50zł</li>
                <li>Krzyścin &ndash; Pag&oacute;rkowa 100zł</li>
                <li>Kubas &ndash; Andrychowska 380 50zł</li>
                <li>J.Kusak &ndash; Andrychowska 238 50zł</li>
                <li>Kuska, Opalach 100zł</li>
                <li>J.Kuska &ndash; Lipowa 73 100zł</li>
                <li>Stanisław Kuska &ndash; Kasztanowa 4 50zł</li>
                <li>A.Kuspik &ndash; Andrychowska 174 100zł</li>
                <li>Łubiarz &ndash; Lipowa 65 100zł</li>
                <li>Madeja &ndash; Andrychowska 31 100zł</li>
                <li>Makuch &ndash; Andrychowska 134 100zł</li>
                <li>Makuch &ndash; Andrychowska 288 100zł</li>
                <li>Makuch &ndash; Andrychowska 232 100zł</li>
                <li>J&oacute;zef Makuch &ndash; Akacjowa 100zł</li>
                <li>Ludwik Makuch &ndash; Andrychowska 316 100zł</li>
                <li>Sebastian Makuch &ndash; Andrychowska 184 300zł</li>
                <li>Waldemar Makuch &ndash; Akacjowa 100zł</li>
                <li>P.Malik &ndash; Modrzewiowa 21 50zł</li>
                <li>Antoni Mamoń &ndash; Andrychowska 131 50zł</li>
                <li>L.Mamoń &ndash; Andrychowska 366 200zł</li>
                <li>Marsowicz &ndash; Andrychowska 155 50zł</li>
                <li>Michalska &ndash; Andrychowska 100zł</li>
                <li>Władysława Michałek &ndash; Andrychowska 98 100zł</li>
                <li>Czesław Helena Miętka &ndash; Kasztanowa 20 100zł</li>
                <li>Mirek &ndash; Lipowa 40 &nbsp; 50zł</li>
                <li>Stanisław Młynarczyk &ndash; Akacjowa 100zł</li>
                <li>E.Nocula &ndash; Andrychowska 346 100zł</li>
                <li>Marianna Nowak &ndash; Modrzewiowa 34 &nbsp; 50zł</li>
                <li>J.Nowak &ndash; Wrzosowa 26 100zł</li>
                <li>Wiktoria Noworyta &nbsp; 50zł</li>
                <li>Nykiel &ndash; Lipowa 72 100zł</li>
                <li>Ortman &ndash; Weglarz&oacute;wka 11 100zł</li>
                <li>S.Ortman &ndash; Kasztanowa 46 200zł</li>
                <li>K.Pieczonka &ndash; Andrychowska 171 100zł</li>
                <li>Płonka &ndash; Andrychowska 299 100zł</li>
                <li>Krystian Urszula Poręba &nbsp; 50zł</li>
                <li>W.Poręba 100zł</li>
                <li>R. - Wrzosowa 17 &nbsp; 50zł</li>
                <li>Rozalia Rola &ndash; Andrychowska 225 100zł</li>
                <li>Beata Sikora &ndash; Andrychowska 160 100zł</li>
                <li>Jadwiga Stanisławek &ndash; Akacjowa 68 100zł</li>
                <li>Stańczyk &ndash; Modrzewiowa 25 100zł</li>
                <li>Stolarczyk &ndash; Andrychowska 263A 100zł</li>
                <li>Szatan &ndash; Andrychowska 272 100zł</li>
                <li>Szymańscy &ndash; Andrychowska 410 &nbsp; 50zł</li>
                <li>Śliwińska, Szostak &ndash; Kasztanowa 112 100zł</li>
                <li>A.Środa &ndash; Akacjowa 77 100zł</li>
                <li>Rafał Monika Tobiczyk &ndash; Andrychowska 97 100zł</li>
                <li>Gabriela Toporek &ndash; Kościelna 8 100zł</li>
                <li>Iwona Adam Wasilewscy &ndash; Pag&oacute;rkowa 10 100zł</li>
                <li>W.Wasztyl 100zł</li>
                <li>Anna Wilgocka &ndash; Andrychowska 103 100zł</li>
                <li>Łukasz Wiśniecki &ndash; Lipowa 100zł</li>
                <li>Włoszek 100zł</li>
                <li>Wnętrzak 200zł</li>
                <li>Wojtala &ndash; Andrychowska 172 &nbsp; 50zł</li>
                <li>Stanisława Kazimierz Ubik &ndash; Wrzosowa 2 100zł</li>
                <li>Aleksandra Zajas &ndash; Pag&oacute;rkowa 100zł</li>
                <li>Antonina Kazimierz Zając &ndash; Modrzewiowa 87 200zł</li>
                <li>K.Zając &ndash; Andrychowska 101 100zł</li>
                <li>Krystyna Zając &ndash; Lipowa 75 100zł</li>
                <li>Krzysztof Zając &ndash; Pag&oacute;rkowa 17 200zł</li>
                <li>Rozalia Jan Zając &ndash; Andrychowska 376 100zł</li>
                <li>Ł.Zaporowscy &ndash; Andrychowska 366 100zł</li>
                <li>Zarzycki &ndash; Andrychowska 312 100zł</li>
                <li>M.Zawierucha &ndash; Lipowa 23 100zł</li>
                <li>Zdżalik &ndash; Wrzosowa 25 &nbsp; 50zł</li>
                <li>J.Zielińscy &ndash; Andrychowska 29 100zł</li>
                <li>Ł.Zięba &ndash; Andrychowska 35 200zł</li>
                <li>C.Zygmunt &ndash; Lipowa 15 100zł</li>
                <li>Andrychowska &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1000zł</li>
                <li>Andrychowska 32 100zł</li>
                <li>Andrychowska 38 100zł</li>
                <li>Andrychowska 153 200zł</li>
                <li>Andrychowska 158 200zł</li>
                <li>Andrychowska 164 200zł</li>
                <li>Andrychowska 245 100zł</li>
                <li>Andrychowska 260 &nbsp; 50zł</li>
                <li>Andrychowska 301 100zł</li>
                <li>Andrychowska 338 &nbsp; 50zł</li>
                <li>Andrychowska 398 500zł</li>
                <li>Jaśminowa 5 100zł</li>
                <li>Kasztanowa 42 200zł</li>
                <li>Kasztanowa 49 &nbsp; 50zł</li>
                <li>Kasztanowa 77 100zł</li>
                <li>Kościelna 16 150zł</li>
                <li>Lipowa 3 100zł</li>
                <li>Lipowa 40 &nbsp; 50zł</li>
                <li>Lipowa 61 100zł</li>
                <li>Wrzosowa 23 100zł</li>
                <li>Wrzosowa 27 &nbsp; 50zł</li>
                <li>Anonimowo 250zł</li>
            </ol>
            </div>
            <Footer />
        </>
)}

export default Ofiarodawcy